import { Box } from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
// ----------------------------------------------------------------------

interface LogoProps {
  sx?: object;
  disabledLink?: boolean;
  size?: any;
  compact?: boolean;
}
export default function Logo({
  disabledLink = false,
  sx,
  size = 40,
  compact,
}: LogoProps) {
  const theme = useTheme();
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = compact ? (
    <Box sx={{ width: size, height: size, ...sx }}>
      <svg
        version="1.1"
        id="Lager_1"
        xmlns="http://www.w3.org/2000/svg"
        width="200%"
        height="100%"
        viewBox="0 0 436.3 129.7"
      >
        <g>
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M127.3,0h-24.1c-5.7,5-43.8,36.5-45.4,37.9c-1.7,1.5-3.4,3.1-5.2,4.6c-1.7,1.6-3.3,3.1-4.6,4.4
c-2.2,2.2-3.5,4.1-3.7,5.7c-0.2,1.6,0.4,3.1,1.8,4.5c1.1,1.1,2.3,1.6,3.7,1.6c1.4,0,3.7-0.5,6.9-1.4l15.3-4.6
c6.2-1.9,11.4-2.2,15.5-1.1c4.1,1.1,7.7,3.3,10.9,6.4c2.1,2.1,3.8,4.5,5.1,7.2c1.3,2.8,1.9,5.8,1.8,9.1c-0.1,3.3-1,6.8-2.6,10.5
c-1.7,3.7-4.5,7.4-8.5,11.3c-2.1,2.1-24.3,20.3-39.9,33.4h73c1.7,0,3.4-1.1,3.4-2.7V3.1C130.7,1.4,129,0,127.3,0z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M79.6,87.7c1.4-1.2,2.5-2.2,3.3-3c2.7-2.7,4.1-4.9,4.2-6.8c0.1-1.8-0.8-3.7-2.7-5.5c-1.1-1.1-2.5-1.8-4-1.9
c-1.5-0.1-3.9,0.3-7.1,1.1L56.2,76c-2.9,0.7-5.5,1.1-7.8,1.1c-2.3,0-4.4-0.2-6.4-0.8C40,75.8,38.2,75,36.6,74
c-1.6-1-3.1-2.3-4.5-3.7c-2.1-2.1-3.8-4.5-5-7c-1.2-2.5-1.7-5.2-1.5-8.1c0.2-2.9,1.2-6,3-9.3c1.8-3.3,4.5-6.9,8.3-10.7
c1.4-1.4,3-2.9,4.7-4.4c1.7-1.5,3.4-3,5.1-4.4C47.7,25.6,65.5,11.1,78.7,0H3.5C1.8,0,0,1.4,0,3.1v123.8c0,1.7,1.8,2.7,3.5,2.7H29
C31.5,127.7,78.2,88.9,79.6,87.7z"
          />
        </g>
      </svg>
    </Box>
  ) : (
    <Box sx={{ width: size, height: size, ...sx }}>
      <svg
        version="1.1"
        id="Lager_1"
        xmlns="http://www.w3.org/2000/svg"
        width="200%"
        height="100%"
        viewBox="0 0 436.3 129.7"
      >
        <g>
          <path
            className="st0"
            d="M194,42.4c0,2-0.3,3.9-1,5.8c-0.7,1.9-1.8,3.7-3.4,5.2c-1.6,1.5-3.7,2.8-6.2,3.7c-2.6,1-5.8,1.4-9.7,1.4
		c-2.9,0-5.5-0.1-7.6-0.4c-2.2-0.3-4.2-0.6-6-1c-0.4-0.1-0.8-0.3-1-0.5c-0.3-0.3-0.4-0.6-0.4-1v-5.6c0-0.4,0.1-0.8,0.4-1
		c0.3-0.3,0.6-0.4,1-0.4h0.2c0.8,0.1,1.8,0.2,3,0.3c1.3,0.1,2.6,0.2,3.9,0.2c1.3,0.1,2.6,0.1,3.8,0.2c1.2,0.1,2.2,0.1,2.9,0.1
		c3.4,0,5.8-0.6,7.1-1.8c1.3-1.2,1.9-2.9,1.9-5.2c0-0.8-0.1-1.5-0.3-2c-0.2-0.6-0.6-1.2-1-1.7c-0.5-0.6-1.2-1.1-2-1.7
		c-0.8-0.6-1.9-1.3-3.2-2L166,28.8c-1.8-1.1-3.3-2.2-4.4-3.2c-1.2-1.1-2.1-2.2-2.8-3.4c-0.7-1.2-1.2-2.4-1.5-3.6
		c-0.3-1.3-0.4-2.6-0.4-3.9c0-2.1,0.3-4.1,1-5.9c0.7-1.8,1.8-3.4,3.3-4.7c1.5-1.3,3.5-2.3,5.9-3c2.4-0.7,5.5-1.1,9.1-1.1
		c1.3,0,2.6,0,4.1,0.1c1.4,0.1,2.9,0.2,4.2,0.3c1.4,0.2,2.6,0.3,3.8,0.5c1.1,0.2,2,0.3,2.7,0.5c0.4,0.1,0.7,0.3,1,0.5
		c0.3,0.2,0.4,0.6,0.4,1v5.7c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.6,0.4-1,0.4H191c-2.6-0.3-5.1-0.4-7.6-0.6c-2.5-0.1-5-0.2-7.6-0.2
		c-2.9,0-5,0.5-6.2,1.4c-1.3,1-1.9,2.3-1.9,4.1c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.5,1.1,1,1.6c0.5,0.5,1.1,1.1,2,1.6
		c0.8,0.5,1.9,1.2,3.1,1.9l9.3,5.2c2.1,1.2,3.9,2.4,5.2,3.6c1.4,1.2,2.5,2.5,3.3,3.7c0.8,1.3,1.4,2.6,1.7,4
		C193.8,39.4,194,40.9,194,42.4z"
            fill={PRIMARY_DARK}
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M235,57c-0.2,0.2-0.6,0.4-1.2,0.5c-0.6,0.1-1.3,0.2-2,0.3c-0.7,0.1-1.7,0.2-2.8,0.3c-1.1,0.1-2.4,0.2-4,0.3
		c-1.6,0.1-3.5,0.1-5.8,0.1c-2.5,0-4.9-0.2-7-0.6c-2.2-0.4-4.1-1.2-5.7-2.4c-1.6-1.2-2.9-2.8-3.8-4.8c-0.9-2.1-1.4-4.7-1.4-8V15.8
		c0-3.3,0.5-6,1.4-8c0.9-2.1,2.2-3.7,3.8-4.8c1.6-1.2,3.5-1.9,5.7-2.4c2.2-0.4,4.5-0.6,7-0.6c2.3,0,4.3,0,5.8,0.1
		c1.6,0.1,2.9,0.2,4,0.3c1.1,0.1,2,0.2,2.8,0.3c0.7,0.1,1.4,0.2,2,0.3c0.6,0.1,1,0.3,1.2,0.5c0.2,0.2,0.3,0.6,0.3,1.2v4.9
		c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5H218c-2.3,0-3.8,0.5-4.7,1.4c-0.9,0.9-1.4,2.7-1.4,5.2v8.3h20.9
		c0.4,0,0.8,0.2,1.1,0.5s0.5,0.7,0.5,1.1v5.6c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h-20.9v10c0,2.6,0.4,4.3,1.4,5.2
		c0.9,0.9,2.5,1.4,4.7,1.4h15.7c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1v4.9C235.3,56.4,235.2,56.8,235,57z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M284.6,57c0,0.6-0.3,0.9-0.9,0.9h-9.4c-0.4,0-0.7-0.2-0.9-0.5c-0.2-0.3-0.4-0.7-0.6-1.1l-11.1-20.5h-1.1
		c-1,0-2.2,0-3.5-0.1c-1.3-0.1-2.4-0.1-3.3-0.2v20.8c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.5-1,0.5h-7.7c-0.4,0-0.8-0.2-1.1-0.5
		c-0.3-0.3-0.5-0.7-0.5-1.1V3.3c0-0.8,0.2-1.3,0.7-1.7c0.4-0.3,1-0.6,1.8-0.7c2-0.3,4.3-0.6,6.9-0.7c2.6-0.2,5.3-0.3,8.2-0.3
		c3,0,5.8,0.3,8.5,0.9c2.7,0.6,5,1.6,7.1,2.9c2,1.3,3.7,3.1,4.9,5.4c1.2,2.2,1.8,5,1.8,8.3v0.7c0,4.1-0.9,7.5-2.8,10
		c-1.9,2.6-4.4,4.5-7.7,5.7l12.1,22.5C284.5,56.6,284.6,56.8,284.6,57z M272.1,17.4c0-2.9-1-5-2.9-6.3c-1.9-1.3-4.8-1.9-8.6-1.9
		c-0.4,0-0.9,0-1.6,0c-0.6,0-1.3,0.1-2,0.1c-0.7,0-1.3,0.1-1.9,0.1c-0.6,0-1.1,0.1-1.4,0.1v17.4c0.3,0,0.8,0,1.4,0
		c0.6,0,1.3,0.1,2,0.1c0.7,0,1.4,0.1,2,0.1c0.6,0,1.1,0,1.4,0c4,0,6.9-0.6,8.8-1.9c1.8-1.3,2.8-3.6,2.8-7V17.4z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M334.2,1.9c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.2l-12.7,48.4c-0.5,1.8-1.1,3.2-2,4.2c-0.9,1-1.8,1.8-2.8,2.4
		c-1,0.5-2,0.9-3.1,1c-1.1,0.2-2,0.2-2.8,0.2c-0.8,0-1.8-0.1-2.8-0.2c-1.1-0.2-2.1-0.5-3.1-1c-1-0.5-1.9-1.3-2.8-2.4
		c-0.9-1-1.5-2.4-2-4.2L287.3,2.3c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.2c0-0.4,0.1-0.7,0.4-1c0.3-0.2,0.6-0.3,1-0.3h8.2
		c0.4,0,0.7,0.1,0.9,0.4c0.3,0.3,0.4,0.6,0.5,0.9l11.2,46.4c0.1,0.5,0.2,0.8,0.4,1c0.2,0.2,0.4,0.3,0.8,0.3c0.4,0,0.7-0.1,0.8-0.3
		c0.2-0.2,0.3-0.5,0.4-1l11.2-46.4c0.1-0.4,0.3-0.7,0.5-0.9c0.3-0.3,0.6-0.4,0.9-0.4h8.2c0.4,0,0.7,0.1,1,0.3
		C334.1,1.2,334.2,1.5,334.2,1.9z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M350.9,57.5c-0.3,0.3-0.7,0.5-1.1,0.5h-7.6c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1V2.1
		c0-0.4,0.2-0.8,0.5-1c0.3-0.3,0.7-0.4,1.1-0.4h7.6c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.6,0.5,1v54.3
		C351.4,56.8,351.2,57.2,350.9,57.5z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M394.8,56.7c-0.3,0.2-0.7,0.4-1.2,0.6c-0.3,0.1-0.8,0.2-1.6,0.3c-0.7,0.2-1.7,0.3-2.7,0.4
		c-1.1,0.1-2.3,0.2-3.6,0.3c-1.3,0.1-2.8,0.2-4.2,0.2c-2.3,0-4.8-0.3-7.3-1c-2.6-0.7-4.9-1.9-7-3.7c-2.1-1.8-3.9-4.2-5.3-7.2
		c-1.4-3-2.1-6.9-2.1-11.7V23.6c0-4.8,0.7-8.8,2.1-11.8c1.4-3.1,3.2-5.5,5.3-7.2c2.1-1.7,4.5-2.9,7-3.6c2.6-0.6,5-1,7.3-1
		c1.5,0,2.9,0.1,4.2,0.2c1.3,0.1,2.5,0.2,3.6,0.3c1.1,0.1,2,0.3,2.7,0.4c0.7,0.1,1.3,0.2,1.6,0.3c0.5,0.2,0.9,0.3,1.2,0.6
		c0.3,0.2,0.5,0.6,0.5,1.2v5.2c0,0.5-0.1,0.8-0.3,1.2c-0.2,0.3-0.6,0.5-1.2,0.5h-0.2c-0.7-0.1-1.5-0.1-2.4-0.2c-1-0.1-2-0.1-3-0.2
		c-1.1-0.1-2.2-0.1-3.3-0.2c-1.1-0.1-2.2-0.1-3.2-0.1c-1.6,0-3.1,0.2-4.5,0.7c-1.4,0.5-2.6,1.3-3.5,2.4c-1,1.1-1.7,2.6-2.3,4.5
		c-0.6,1.9-0.8,4.1-0.8,6.9v11.3c0,2.7,0.3,4.9,0.8,6.8c0.6,1.9,1.3,3.3,2.3,4.5c1,1.1,2.2,1.9,3.5,2.4c1.4,0.5,2.9,0.7,4.5,0.7
		c1,0,2.1,0,3.2-0.1c1.1-0.1,2.2-0.1,3.3-0.2c1.1-0.1,2.1-0.1,3-0.2c1-0.1,1.8-0.1,2.4-0.2h0.2c0.6,0,1,0.2,1.2,0.5
		c0.2,0.3,0.3,0.7,0.3,1.2v5.2C395.2,56.1,395.1,56.5,394.8,56.7z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M435.9,57c-0.2,0.2-0.6,0.4-1.2,0.5c-0.6,0.1-1.3,0.2-2,0.3c-0.7,0.1-1.7,0.2-2.8,0.3c-1.1,0.1-2.4,0.2-4,0.3
		c-1.6,0.1-3.5,0.1-5.8,0.1c-2.5,0-4.9-0.2-7-0.6c-2.2-0.4-4.1-1.2-5.7-2.4c-1.6-1.2-2.9-2.8-3.8-4.8c-0.9-2.1-1.4-4.7-1.4-8V15.8
		c0-3.3,0.5-6,1.4-8c0.9-2.1,2.2-3.7,3.8-4.8c1.6-1.2,3.5-1.9,5.7-2.4c2.2-0.4,4.5-0.6,7-0.6c2.3,0,4.3,0,5.8,0.1
		c1.6,0.1,2.9,0.2,4,0.3c1.1,0.1,2,0.2,2.8,0.3c0.7,0.1,1.4,0.2,2,0.3c0.6,0.1,1,0.3,1.2,0.5c0.2,0.2,0.3,0.6,0.3,1.2v4.9
		c0,0.4-0.2,0.8-0.5,1.1s-0.7,0.5-1.1,0.5H419c-2.3,0-3.8,0.5-4.7,1.4c-0.9,0.9-1.4,2.7-1.4,5.2v8.3h20.9c0.4,0,0.8,0.2,1.1,0.5
		s0.5,0.7,0.5,1.1v5.6c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h-20.9v10c0,2.6,0.4,4.3,1.4,5.2c0.9,0.9,2.5,1.4,4.7,1.4
		h15.7c0.4,0,0.8,0.2,1.1,0.5s0.5,0.7,0.5,1.1v4.9C436.3,56.4,436.2,56.8,435.9,57z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M201.7,128.3c-0.6,0.6-1.3,0.9-2.2,0.9h-7.8c-0.9,0-1.8-0.1-2.6-0.3c-0.8-0.2-1.5-1-2.1-2.4l-17.1-37.6
		c-0.2-0.4-0.4-0.6-0.7-0.6c-0.3,0-0.4,0.2-0.4,0.6v38.8c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.5-1,0.5h-6.6c-0.4,0-0.8-0.2-1.1-0.5
		c-0.3-0.3-0.5-0.7-0.5-1.1V74.9c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9h8.3c0.8,0,1.5,0.2,2,0.5c0.5,0.3,1,1,1.4,2l18,39.7
		c0.2,0.4,0.4,0.6,0.6,0.6c0.3,0,0.4-0.2,0.4-0.7V73.3c0-1,0.5-1.5,1.5-1.5h6.6c1,0,1.5,0.5,1.5,1.5v52.8
		C202.6,127,202.3,127.7,201.7,128.3z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M253.1,116.6c-1.1,3.1-2.6,5.6-4.6,7.5c-2,1.9-4.3,3.4-6.9,4.3c-2.7,0.9-5.6,1.4-8.8,1.4
		c-3.2,0-6.1-0.5-8.8-1.4c-2.7-0.9-5-2.4-6.9-4.3c-2-1.9-3.5-4.4-4.6-7.5c-1.1-3.1-1.7-6.7-1.7-10.9V95.3c0-4.3,0.6-7.9,1.7-11
		c1.1-3,2.6-5.5,4.6-7.4c2-1.9,4.3-3.4,6.9-4.3c2.7-0.9,5.6-1.4,8.8-1.4c3.2,0,6.1,0.5,8.8,1.4c2.7,0.9,5,2.4,6.9,4.3
		c2,1.9,3.5,4.4,4.6,7.4c1.1,3,1.7,6.7,1.7,11v10.4C254.8,109.9,254.2,113.6,253.1,116.6z M244,95.3c0-5.1-0.9-8.9-2.8-11.2
		c-1.8-2.3-4.7-3.5-8.5-3.5c-3.8,0-6.7,1.2-8.5,3.5c-1.8,2.3-2.8,6.1-2.8,11.2v10.4c0,5.1,0.9,8.9,2.8,11.2c1.8,2.3,4.7,3.5,8.5,3.5
		c3.8,0,6.7-1.2,8.5-3.5c1.8-2.3,2.8-6.1,2.8-11.2V95.3z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M298.1,123.7c-4.2,4-10.2,6.1-17.9,6.1c-1.4,0-2.8,0-4.4-0.1c-1.5-0.1-3-0.1-4.4-0.2c-1.4-0.1-2.7-0.2-3.9-0.3
		c-1.2-0.1-2-0.2-2.6-0.3c-0.7-0.1-1.2-0.3-1.5-0.7c-0.3-0.4-0.5-0.9-0.5-1.7V74.5c0-0.8,0.2-1.3,0.5-1.7c0.3-0.4,0.8-0.6,1.5-0.7
		c0.6-0.1,1.4-0.2,2.6-0.3c1.2-0.1,2.4-0.2,3.9-0.3c1.4-0.1,2.9-0.2,4.4-0.2c1.5-0.1,3-0.1,4.4-0.1c7.8,0,13.7,2,17.9,6.1
		c4.2,4,6.3,9.9,6.3,17.6v11.3C304.4,113.8,302.3,119.7,298.1,123.7z M293.6,94.9c0-2.9-0.3-5.3-1-7.2c-0.7-1.9-1.6-3.4-2.8-4.5
		c-1.2-1.1-2.6-1.9-4.3-2.3c-1.6-0.4-3.4-0.7-5.4-0.7c-1.4,0-2.7,0-3.7,0.1c-1,0.1-1.9,0.1-2.8,0.2v40.1c0.8,0.1,1.7,0.1,2.8,0.2
		c1,0.1,2.3,0.1,3.7,0.1c1.9,0,3.7-0.2,5.4-0.7c1.6-0.4,3.1-1.2,4.3-2.3c1.2-1.1,2.2-2.6,2.8-4.5c0.7-1.9,1-4.3,1-7.2V94.9z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M346.1,128.2c-0.2,0.2-0.6,0.4-1.2,0.5c-0.6,0.1-1.3,0.2-2,0.3c-0.7,0.1-1.7,0.2-2.8,0.3
		c-1.1,0.1-2.4,0.2-4,0.3c-1.6,0.1-3.5,0.1-5.8,0.1c-2.5,0-4.9-0.2-7-0.6c-2.2-0.4-4.1-1.2-5.7-2.4c-1.6-1.2-2.9-2.8-3.8-4.8
		c-0.9-2.1-1.4-4.7-1.4-8V87c0-3.3,0.5-6,1.4-8c0.9-2.1,2.2-3.7,3.8-4.8c1.6-1.2,3.5-1.9,5.7-2.4c2.2-0.4,4.5-0.6,7-0.6
		c2.3,0,4.3,0,5.8,0.1c1.6,0.1,2.9,0.2,4,0.3c1.1,0.1,2,0.2,2.8,0.3c0.7,0.1,1.4,0.2,2,0.3c0.6,0.1,1,0.3,1.2,0.5
		c0.2,0.2,0.3,0.6,0.3,1.2v4.9c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h-15.7c-2.3,0-3.8,0.5-4.7,1.4
		c-0.9,0.9-1.4,2.7-1.4,5.2v8.3H344c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1v5.6c0,0.4-0.2,0.8-0.5,1.1
		c-0.3,0.3-0.7,0.5-1.1,0.5h-20.9v10c0,2.6,0.4,4.3,1.4,5.2c0.9,0.9,2.5,1.4,4.7,1.4h15.7c0.4,0,0.8,0.2,1.1,0.5
		c0.3,0.3,0.5,0.7,0.5,1.1v4.9C346.4,127.6,346.3,128,346.1,128.2z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M378.4,83.7c0,1.7-0.3,3.3-1,4.8c-0.6,1.5-1.5,2.8-2.7,3.9c-1.1,1.1-2.4,2-4,2.7c-1.5,0.6-3.1,1-4.8,1
		c-1.7,0-3.3-0.3-4.8-1c-1.5-0.6-2.8-1.5-3.9-2.7c-1.1-1.1-2-2.4-2.7-3.9c-0.6-1.5-1-3.1-1-4.8c0-1.7,0.3-3.3,1-4.8
		c0.6-1.5,1.5-2.8,2.7-3.9c1.1-1.1,2.4-2,3.9-2.7c1.5-0.6,3.1-1,4.8-1c1.7,0,3.3,0.3,4.8,1c1.5,0.6,2.8,1.5,4,2.7
		c1.1,1.1,2,2.4,2.7,3.9C378.1,80.4,378.4,82,378.4,83.7z M376.3,83.7c0-1.4-0.3-2.7-0.8-4c-0.5-1.3-1.3-2.4-2.2-3.3
		c-0.9-0.9-2-1.7-3.3-2.2c-1.3-0.5-2.6-0.8-4-0.8c-1.4,0-2.7,0.3-4,0.8c-1.2,0.5-2.3,1.3-3.3,2.2c-0.9,0.9-1.7,2-2.2,3.3
		c-0.5,1.3-0.8,2.6-0.8,4s0.3,2.7,0.8,4c0.5,1.2,1.3,2.3,2.2,3.3c0.9,0.9,2,1.7,3.3,2.2c1.2,0.5,2.6,0.8,4,0.8c1.4,0,2.7-0.3,4-0.8
		c1.3-0.5,2.4-1.3,3.3-2.2c0.9-0.9,1.7-2,2.2-3.3C376,86.4,376.3,85.1,376.3,83.7z M372,90.6c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.2-0.4,0.2h-2.3c-0.2,0-0.4-0.1-0.5-0.4l-3.1-5.3c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.2-0.1h-0.9h-0.5v5.3
		c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2h-2.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4V77.2
		c0-0.4,0.1-0.7,0.2-0.9c0.2-0.2,0.4-0.3,0.8-0.3c0.2,0,0.4-0.1,0.7-0.1c0.3,0,0.6,0,1,0c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1,0
		c1.9,0,3.4,0.3,4.5,1c1.1,0.7,1.6,1.9,1.6,3.7v0.3c0,1.1-0.3,2-0.8,2.6c-0.5,0.6-1.2,1.1-2,1.4l3.2,5.5
		C371.9,90.4,372,90.5,372,90.6z M368.4,80.5c0-0.7-0.2-1.3-0.7-1.6c-0.5-0.3-1.2-0.4-2.2-0.4h-1.4h-0.5v4.3c0.1,0,0.2,0,0.4,0
		c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.4,0c1,0,1.8-0.2,2.2-0.5c0.4-0.3,0.7-0.9,0.7-1.6V80.5z"
          />
        </g>
        <g>
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M127.3,0h-24.1c-5.7,5-43.8,36.5-45.4,37.9c-1.7,1.5-3.4,3.1-5.2,4.6c-1.7,1.6-3.3,3.1-4.6,4.4
		c-2.2,2.2-3.5,4.1-3.7,5.7c-0.2,1.6,0.4,3.1,1.8,4.5c1.1,1.1,2.3,1.6,3.7,1.6c1.4,0,3.7-0.5,6.9-1.4l15.3-4.6
		c6.2-1.9,11.4-2.2,15.5-1.1c4.1,1.1,7.7,3.3,10.9,6.4c2.1,2.1,3.8,4.5,5.1,7.2c1.3,2.8,1.9,5.8,1.8,9.1c-0.1,3.3-1,6.8-2.6,10.5
		c-1.7,3.7-4.5,7.4-8.5,11.3c-2.1,2.1-24.3,20.3-39.9,33.4h73c1.7,0,3.4-1.1,3.4-2.7V3.1C130.7,1.4,129,0,127.3,0z"
          />
          <path
            className="st0"
            fill={PRIMARY_DARK}
            d="M79.6,87.7c1.4-1.2,2.5-2.2,3.3-3c2.7-2.7,4.1-4.9,4.2-6.8c0.1-1.8-0.8-3.7-2.7-5.5c-1.1-1.1-2.5-1.8-4-1.9
		c-1.5-0.1-3.9,0.3-7.1,1.1L56.2,76c-2.9,0.7-5.5,1.1-7.8,1.1c-2.3,0-4.4-0.2-6.4-0.8C40,75.8,38.2,75,36.6,74
		c-1.6-1-3.1-2.3-4.5-3.7c-2.1-2.1-3.8-4.5-5-7c-1.2-2.5-1.7-5.2-1.5-8.1c0.2-2.9,1.2-6,3-9.3c1.8-3.3,4.5-6.9,8.3-10.7
		c1.4-1.4,3-2.9,4.7-4.4c1.7-1.5,3.4-3,5.1-4.4C47.7,25.6,65.5,11.1,78.7,0H3.5C1.8,0,0,1.4,0,3.1v123.8c0,1.7,1.8,2.7,3.5,2.7H29
		C31.5,127.7,78.2,88.9,79.6,87.7z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

import { MotionLazyContainer } from "components/animate";
import NotistackProvider from "components/NotistackProvider";
import ScrollToTop from "components/ScrollToTop";
import ThemeSettings from "components/settings";
import Router from "Router";
import ThemeProvider from "theme";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
const FAS: any = fas;
const FAR: any = far;
const FAL: any = fal;
const FAB: any = fab;
library.add(FAS, FAR, FAL, FAB);

function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

export default App;

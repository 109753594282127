import axios from "axios";
import { HOST_API } from "../config";

// ----------------------------------------------------------------------

const api = axios.create({
  baseURL: HOST_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

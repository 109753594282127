import { createSlice } from "@reduxjs/toolkit";
import api from "app/api";
import appURL from "app/urls";
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  objects: [],
  isInitialized: false,
};

const slice = createSlice({
  name: "object",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isInitialized = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
    },

    // GET OBJECTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.objects = action.payload;
      state.isInitialized = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getObjects(uuid, path) {
  dispatch(slice.actions.startLoading());
  try {
    const promiseArr = ["assets", "places"]?.map((item) => {
      return api.get(`${appURL.PUBLIC}${uuid}/${path}/${item}/`);
    });

    Promise.all(promiseArr)
      .then((res: any) => {
        if (res.length > 0) {
          dispatch(
            slice.actions.getProductsSuccess(
              res.map((objects) => objects.data).flat()
            )
          );
        }
      })
      .catch((e) => {
        dispatch(slice.actions.hasError(e));
      });
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

import {
  AppBar,
  AppBarProps,
  Box,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import Iconify from "components/Iconify";
import useResponsive from "hooks/useResponsive";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSideBarOpen } from "redux/slices/sidebar";
// components
// config
import { HEADER, NAVBAR } from "../../../config";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
// utils
import cssStyles from "../../../utils/cssStyles";
import LanguagePopover from "./LanguagePopover";
//

// ----------------------------------------------------------------------
interface RootStyle extends AppBarProps {
  isCollapse?: boolean;
  isOffset?: boolean;
  verticalLayout?: boolean;
  theme?: any;
}
const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})<RootStyle>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

interface IDashboardHeader {
  isCollapse?: boolean;
  verticalLayout?: boolean;
  onOpenSidebar?: () => void;
}
export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: IDashboardHeader) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const companyInfo = useAppSelector((state) => state.conmpany);
  const isDesktop = useResponsive("up", "md");
  const dispatch = useAppDispatch();
  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {!isDesktop && (
          <IconButton
            onClick={() => {
              dispatch(setSideBarOpen(true));
            }}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
        {companyInfo?.data?.created_by?.company?.logo ? (
          <img
            src={companyInfo?.data?.created_by?.company?.logo}
            alt={"companyLogo"}
            loading="lazy"
            style={{ height: 42 }}
          />
        ) : (
          companyInfo?.data?.created_by?.company?.company_name
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import conmpanyReducer from "./slices/company";
import objectReducer from "./slices/object";
import productReducer from "./slices/product";
import sidebarReducer from "./slices/sidebar";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  conmpany: conmpanyReducer,
  object: objectReducer,
  sideBar: sidebarReducer,
});

export { rootPersistConfig, rootReducer };
export type RootState = ReturnType<typeof rootReducer>;

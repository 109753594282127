import { createSlice } from "@reduxjs/toolkit";
import api from "app/api";
import appURL from "app/urls";
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  isInitialized: false,
  data: null,
};

const userSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isInitialized = false;
    },
    // HAS ERROR
    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = false;
      state.isInitialized = true;
    },
  },
});

// Reducer
const { reducer } = userSlice;

export default reducer;

export function getCompanyInfo(uuid, path) {
  if (!uuid || !path) return;

  dispatch(userSlice.actions.startLoading());
  try {
    api
      .get(`${appURL.PUBLIC}page/${uuid}/${path}/`)
      .then((res) => {
        dispatch(userSlice.actions.getProductSuccess(res.data));
      })
      .catch((e) => {
        dispatch(userSlice.actions.hasError());
        throw e;
      });
  } catch (error) {
    dispatch(userSlice.actions.hasError());
  }
}

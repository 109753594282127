const API_VERSION = process.env.REACT_APP_API_VERSION;
const base = `/${API_VERSION}`;

const PUBLIC = `${base}/public/`;
const PUBLIC_ERROR = `${base}/public/error/`;
const appURL = {
  PUBLIC,
  PUBLIC_ERROR,
};
export default appURL;
